import { GenericDevice } from "./GenericDevice";

export class Device implements GenericDevice {
  id!: string;

  type!: string;

  serialNumber!: string;

  sku?: string;

  shortSerial!: string;

  sigfoxId?: string;

  sigfoxPac?: string;

  hardwareVersion!: string;

  firmwareVersion!: string;

  quickCircuitId?: string;

  createdAt!: string;

  updatedAt!: string;

  deletedAt!: string;

  subDeviceIndex!: number;

  network!: string;

  constructor(props: Device) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore problems with next line
    Object.keys(props).forEach((key) => (this[key as keyof Device] = props[key as keyof Device] as any));
  }

  getDeviceType(): string {
    return this.type;
  }

  getPhysicalLabel(): string {
    return this.serialNumber;
  }
}
